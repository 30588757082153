import React, { useState } from "react";
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";

import { GiHamburger } from "react-icons/gi";
import { useTheme } from "styled-components";

// Define an interface for your DropdownMenu props
interface DropdownMenuProps {
  isOpen: boolean;
}

const MenuIcon = styled.div`
  display: none; // Hide by default
  top: 10px;
  right: 10px;
  z-index: 100; // Above other content
  color: ${({ theme }) => theme.texts.primary};
  transition: color 0.5s ease;

  @media (max-width: 1024px) {
    display: block; // Only show on small screens
  }
`;

// Use the interface for the styled component
const DropdownMenu = styled.div<DropdownMenuProps>`
  position: absolute;
  top: 100%; // Position the dropdown right below the Navbar
  left: 0;
  right: 0;
  width: 100vw;
  background-color: ${({ theme }) => theme.backgroundColor};
  box-sizing: border-box;
  z-index: 10; // Ensure dropdown is on top of other elements

  overflow: hidden; // Hide the overflow when closed
  max-height: ${({ isOpen }) =>
    isOpen ? "400px" : "0"}; // Adjust max height as needed
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")}; // Smooth opacity transition
  visibility: ${({ isOpen }) =>
    isOpen ? "visible" : "hidden"}; // Hide the element when closed
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease,
    visibility 0.3s ease; // Smooth transitions

  padding: 10px 0; // Add some padding to the container

  // Fixed shadow (no theme-based color)
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1); // Consistent shadow color for both light and dark modes

  @media (min-width: 1024px) {
    display: none;
  }

  // Link styles
  a {
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.heading};
    color: ${({ theme }) => theme.texts.primary};
    display: block;
    padding: 15px 20px; // Adjust padding for better touch target size
    text-decoration: none;
    flex: none;
    transition: background-color 0.5s ease, color 0.5s ease;

    &:hover {
      background-color: #1974b4;
      color: #ffffff; // Change text color on hover
    }
  }

  // Handle long dropdowns with scroll
  overflow-y: auto;
  max-height: ${({ isOpen }) => (isOpen ? "400px" : "0")};
`;

// The component
const MobileMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleNavItemClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <MenuIcon onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <IoClose size={39} /> : <RxHamburgerMenu size={39} />}
      </MenuIcon>
      <DropdownMenu isOpen={isOpen}>
        {/* List your menu items here */}
        <a onClick={handleNavItemClick} href="#experience">
          Experience
        </a>
        <a onClick={handleNavItemClick} href="#education">
          Education
        </a>
        <a onClick={handleNavItemClick} href="#projects">
          Projects
        </a>
        <a onClick={handleNavItemClick} href="#skills">
          Skills
        </a>

        {/* <a onClick={handleNavItemClick} href="#projects">
          Projects
        </a> */}
      </DropdownMenu>
    </>
  );
};

export default MobileMenu;
