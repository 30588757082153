import React from "react";
import ThemeToggleButton from "../components/ThemeToggleButton/ThemeToggleButton";
import styled from "styled-components";
import MobileMenu from "./MenuIcon";

const NavBarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  z-index: 10;
  gap: 1rem;
  transition: background-color 0.5s ease;
  background-color: transparent;

  @media (max-width: 1650px) {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
  }

  @media (max-width: 1024px) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    z-index: 1000;
    background-color: ${({ theme }) => theme.backgroundColor};
  }
`;

const NavItem = styled.a`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.texts.primary};
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.5s ease;
  position: relative;

  @media (max-width: 1024px) {
    display: none; // Hide on small screens, since mobile menu will be used
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.hover_color};
    transition: width 0.3s ease, left 0.3s ease;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
`;

const Navbar = () => {
  return (
    <NavBarContainer>
      <NavItem href="#experience">Experience</NavItem>
      <NavItem href="#education">Education</NavItem>
      <NavItem href="#projects">Projects</NavItem>
      <NavItem href="#skills">Skills</NavItem>

      <ThemeToggleButton />

      {/* Integrate MobileMenu here */}
      <MobileMenu />
    </NavBarContainer>
  );
};

export default Navbar;
